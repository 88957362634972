<div class="modal-header">
  <h5 class="modal-title">{{ 'global.import.Import traductions' | translate }}</h5>
  <button class="btn btn-close" type="button" data-dismiss="modal" (click)="modal.dismiss()" aria-label="Close">
    <i class="fa-light fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    @if (langueUpdatedChanged !== null) {
      <ng-container>
        <div class="row">
          <div class="col">
            <table class="table">
              <tr>
                <th>{{ 'global.import.Langue' | translate }}</th>
                <th class="text-center">{{ 'global.import.Mis à jour' | translate }}</th>
                <th class="text-center">{{ 'global.import.Non traduits' | translate }}</th>
                <th class="text-center">{{ 'global.import.Non modifiés' | translate }}</th>
              </tr>
              @for (luc of langueUpdatedChanged; track $index) {
                <tr class="border-bottom">
                  <td>{{ luc.langue }}</td>
                  <td class="text-center">{{ luc.nbUpdated }}</td>
                  <td class="text-center">{{ luc.nbNotTranslated }}</td>
                  <td class="text-center">{{ luc.nbUnchanged }}</td>
                </tr>
              }
            </table>
          </div>
        </div>
      </ng-container>
    }
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="modal.dismiss()">
    {{ "global.import.Valider l'import" | translate }}
  </button>
</div>
